<template>
  <div>
    <v-row no-gutters justify="space-between" align="center">
      <v-btn large depressed tile @click="addCurrencyCode">
        <v-icon left>mdi-application-outline </v-icon>
        Add price list
      </v-btn>
      <v-col cols="4">
        <search-text-field v-model="search" />
      </v-col>
    </v-row>
    <v-data-table
      :loading="isLoading"
      @click:row="showDetails"
      :headers="headers"
      :items="populatedLists"
      :search="search"
      v-bind="$util.dataTableAttributes()"
    >
      <template #[`item.name`]="{ item }">
        <router-link :to="{ name: 'PriceListDetails', params: { Id: item.id } }">{{ item.name }}</router-link>
      </template>
    </v-data-table>
    <create-edit-price-list ref="CreateEditList" @item-updated="getLists" />
  </div>
</template>

<script>
import apiService from '@/services/apiService.js';
import CreateEditPriceList from './Dialogs/CreateEditPriceList.vue';

export default {
  name: 'PriceListList',
  components: { CreateEditPriceList },
  data: () => ({
    priceLists: [],
    codes: [],
    isLoading: false,
    search: null,
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Name',
          value: 'name',
        },
        ...this.codes.map((code, index) => ({
          text: code.name,
          value: `codes[${index}]`,
        })),
      ];
    },
    populatedLists() {
      return this.priceLists.map((list) => ({
        ...list,
        codes: this.codes.map((code) => list.values.find((val) => val.currencyCodeId === code.id)?.priceFactor),
      }));
    },
  },
  watch: {
    '$route.name'(next) {
      if (next === 'PriceListList') {
        this.getLists();
      }
    }
  },
  methods: {
    async getLists() {
      this.isLoading = true;
      this.priceLists = await apiService.getPriceLists();
      this.codes = await apiService.getCurrencyCodes();
      this.isLoading = false;
    },
    addCurrencyCode() {
      this.$refs.CreateEditList.addItem();
    },
    showDetails(currencyCode) {
      this.$router.push({ name: 'PriceListDetails', params: { Id: currencyCode.id } });
    },
  },
  created() {
    this.getLists();
  },
};
</script>